import React from 'react';
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {getImage} from "gatsby-plugin-image";

const CommunityGovernedSection = ({contentful}) => {
    return (
            <div className={'container container-slim'}>
                <div className={'flex mb-12'}>
                    <div className={'basis-auto'}>
                        <h3>{contentful.title}</h3>
                        <h6>{contentful.body && renderRichText(contentful.body)}</h6>
                    </div>
                </div>
                <div className={'grid gap-16 grid-cols-1 md:grid-cols-3'}>
                    {
                        contentful.elements.map(function (element, index) {
                            return (
                                <div className={''} key={index}>
                                    <div className={'flex flex-wrap'}>
                                        <div className={'basis-full'}>
                                            <img className={'mb-2.5'} src={element.image.url} alt={element.title}/>
                                        </div>
                                        <div className={'basis-full'}>
                                            <div className={'title'}>
                                                {element.title}
                                            </div>
                                            <div className={'description'}>
                                                {element.body && renderRichText(element.body)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
    );
};

export default CommunityGovernedSection;
