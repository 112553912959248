import React from 'react';
import {renderRichText} from "gatsby-source-contentful/rich-text";

const BehindTheScenesSection = ({contentful}) => {
    return (
        <div className={'container container-slim'}>
            <div className={'flex flex-wrap lg:mb-20'}>
                <div className={'basis-full pb-16'}>
                    <h3>{contentful.title}</h3>
                </div>
                <div className={'basis-full'}>
                    <ul className={'behind-scenes'}>
                    {
                        contentful.elements.map(function (element, index) {
                            return (
                                <li className={'pb-20'} key={index}>
                                    <div className={'pl-20 md:pl-28'}>
                                        {element.body && renderRichText(element.body)}
                                    </div>
                                </li>
                            )
                        })
                    }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default BehindTheScenesSection;
